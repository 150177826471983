import gql from 'graphql-tag';

export const GET_INSTITUTIONS = gql`
  query getInstitutions {
    getInstitutions {
      id
      name
      managers {
        id
        fullName
      }
      creator {
        id
        fullName
      }
      users {
        id
        fullName
      }
      courses {
        id
        name
      }
    }
  }
`;

export const GET_INSTITUTIONS_SELECT = gql`
  query getInstitutions {
    getInstitutions {
      id
      name
    }
  }
`;

export const GET_INSTITUTION_BY_ID = gql`
  query getInstitutionById($id: ID!) {
    getInstitutionById(id: $id) {
      id
      name
      managers {
       id
       fullName
     }
     creator {
       id
       fullName
     }
     users {
       id
       fullName
     }
     courses {
       id
       name
     }
    }
  }
`;
