import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import { SIGNIN } from '../../services/mutations/user';
import { getToken, login, logout } from '../../services/auth';

const useSignIn = (refetchUser) => {
  const [signIn, { loading }] = useMutation(SIGNIN);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [token, setToken] = useState(getToken());

  const mutation = async () => {
    const result = await signIn({
      variables: {
        email, password,
      },
    });

    login(result.data.signIn);

    setToken(result.data.signIn);

    await refetchUser();

  };

  const signOut = async () => {
    logout();
    setToken(undefined);
    await refetchUser();

  };

  return {
    mutation,
    loading,
    email,
    token,
    setEmail,
    password,
    setPassword,
    error,
    setError,
    signOut,
  };
};

export default useSignIn;
