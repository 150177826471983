import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_TAG } from '../../../services/mutations/tag';

const useTagCard = () => {
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [originalName, setOriginalName] = useState();
  const [originalDescription, setOriginalDescription] = useState();
  const [updateTag] = useMutation(UPDATE_TAG);

  return {
    showModal,
    setShowModal,
    id,
    setId,
    name,
    setName,
    description,
    setDescription,
    originalName,
    setOriginalName,
    originalDescription,
    setOriginalDescription,
    updateTag,
  };
};

export default useTagCard;
