import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { GET_TAGS } from '../../services/queries/tag';
import useManualQuery from '../useManualQuery';
import { CREATE_TAG, REMOVE_TAG } from '../../services/mutations/tag';
import useTagCard from './tagCard';

const useTags = () => {
  const getTags = useManualQuery(GET_TAGS);
  const [createTag] = useMutation(CREATE_TAG);
  const [removeTag] = useMutation(REMOVE_TAG);
  const [open, setOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [doDelete, setDoDelete] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [newTagDescription, setNewTagDescription] = useState('');
  const tagCard = useTagCard();

  return {
    getTags,
    createTag,
    removeTag,
    open,
    setOpen,
    selectedTags,
    setSelectedTags,
    showModal,
    setShowModal,
    doDelete,
    setDoDelete,
    newTagName,
    setNewTagName,
    newTagDescription,
    setNewTagDescription,
    tagCard,
  };
};

export default useTags;
