import gql from 'graphql-tag';

export const CREATE_TAG = gql`
  mutation createTag($name: String!, $description: String!) {
    createTag(name: $name, description: $description) {
      id
    }
  }
`;

export const REMOVE_TAG = gql`
  mutation removeTag($id: ID!) {
    removeTag(id: $id)
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag($id: ID!, $name: String!, $description: String!) {
    updateTag(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const ADD_TAG_TO_QUESTION = gql`
  mutation($tag: ID!, $question: ID!, $type: QuestionTypes!) {
    addTagToQuestion(
      tag: $tag,
      question: $question,
      type: $type
    ) {
      __typename
    }
  }
`;

export const REMOVE_TAG_FROM_QUESTION = gql`
  mutation($tag: ID!, $question: ID!, $type: QuestionTypes) {
    removeTagFromQuestion(tag: $tag, question: $question, type: $type) {
      __typename
    }
  }
`
