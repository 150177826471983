import gql from 'graphql-tag';

export const GET_ROLES_SELECT = gql`
  query getRoles {
    getRoles {
      id
      name
    }
  }
`;
