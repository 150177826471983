import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useState } from 'react';

const useManualQuery = (gql, policy) => {
  const client = useApolloClient();
  const [queryOptions, setQueryOptions] = useState({
    skip: true,
    fetchPolicy: policy ? policy : "cache-and-network",
  });
  const query = useQuery(gql, { client, ...queryOptions});

  if (query.data && Object.keys(query.data).length === 0) {
    query.data = undefined;
  }

  return {
    ...query,
    fetch: (options) => {
      if (options) {
        setQueryOptions(options);
      } else {
        setQueryOptions();
      }
    },
  };
};

export default useManualQuery;
