import gql from 'graphql-tag';

export const SIGNUP = gql`
  mutation signUp($fullName: String!, $email: String!, $birthDate: Date!, $password: String!) {
    signUp(fullName: $fullName, email: $email, birthDate: $birthDate, password: $password) {
      id
      fullName
    }
  }
`;

export const SIGNIN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password)
  }
`;

export const CREATE_USER = gql`
  mutation($email: String!, $institution: ID!, $roles: [ID]!) {
    createUser(email: $email, institution: $institution, roles: $roles) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($id: ID!, $institution: ID!, $roles: [ID]!, $fullName: String, $birthDate: Date) {
    updateUserById(id: $id, institution: $institution, roles: $roles, fullName: $fullName, birthDate: $birthDate) {
      id
    }
  }
`;

export const UPDATE_USER_PROFILE_INFO = gql`
  mutation( $fullName: String, $birthDate: Date, $avatar:String) {
    updateUser(fullName: $fullName, birthDate: $birthDate, avatar:$avatar) {
      id
    }
  }
`;


export const UPDATE_USER_PASSWORD = gql`
  mutation( $oldPassword: String!, $newPassword: String!) {
    updateUserPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const REMOVE_USERS = users => gql`
  mutation {
    ${users.map((user, i) => `
      remove${i}: removeUser(id: "${user.id}")
    `).join('')}
  }
`;


export const FAVORITE_UNIT = gql`
  mutation($id: ID!, $favorite: Boolean!) {
    favoriteUnit(id: $id, favorite: $favorite) {
      id
    }
  }
`;

export const LIKE_UNIT = gql`
  mutation($id: ID!, $like: Boolean!) {
    likeUnit(id: $id, like: $like) {
      id
    }
  }
`;

